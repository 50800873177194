import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { map, Observable, of, Subject, takeUntil } from 'rxjs';

import { CompaniesList } from '@shure/cloud/shared/switch-organization/data-access';
import { CloseTextOption, noWhitespaceValidator, SnackbarService } from '@shure/cloud/shared/ui/components';

import { SwitchOrganizationService } from '../../services/switch-organization.service';

@Component({
	selector: 'sh-create-organization-dialog',
	templateUrl: './create-organization-dialog.component.html',
	styleUrls: ['./create-organization-dialog.component.scss']
})
export class CreateOrganizationDialogComponent implements OnInit, OnDestroy {
	private readonly destroy$ = new Subject<void>();
	private myCompaniesList!: CompaniesList[];
	public formGroup!: FormGroup;
	public isVerified = false;
	public selectedCompanyOption!: CompaniesList | null;
	public filteredOptions!: Observable<CompaniesList[]>;

	constructor(
		public formBuilder: FormBuilder,
		private dialogRef: MatDialogRef<CreateOrganizationDialogComponent>,
		public switchOrganizationService: SwitchOrganizationService,
		private snackBarService: SnackbarService,
		private translocoService: TranslocoService
	) {}

	public ngOnInit(): void {
		this.getCompanies();
		this.buildFormGroup();
	}

	public getCompanies(): void {
		this.switchOrganizationService
			.getMyCompanies()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (companiesListResp) => {
					this.myCompaniesList = companiesListResp.body?.body?.items || [];
				},
				error: (error) => {
					const errorMessage = error.error;
					this.snackBarService.open(
						this.translocoService.translate(
							errorMessage?.i18nKey
								? 'cloud-licensing-portal.messages.' + errorMessage.i18nKey
								: errorMessage.message
						),
						CloseTextOption.Ok
					);
				}
			});
	}

	public buildFormGroup(): void {
		this.formGroup = this.formBuilder.group({
			organizationName: new FormControl('', [
				Validators.required,
				noWhitespaceValidator(),
				Validators.maxLength(150)
			]),
			companyName: new FormControl('', [noWhitespaceValidator()])
			// The below code can be uncommented when we support the verified organizations feature
			// isVerified: new FormControl(false, [Validators.requiredTrue])
		});
		this.handleCompanyValueChanges();
	}

	public handleCompanyValueChanges(): void {
		// eslint-disable-next-line dot-notation
		this.filteredOptions = this.formGroup.controls['companyName'].valueChanges.pipe(
			map((value) => {
				this.selectedCompanyOption =
					this.myCompaniesList.find((company) => company.companyName?.trim() === value) ?? null;
				// The below code can be uncommented when we support the verified organizations feature
				// if (this.selectedCompanyOption) {
				// 	// Dynamically update the validation for isVerified based on selectedOption.isVerified
				// 	if (this.selectedCompanyOption.isVerified) {
				// 		// Remove validation if the selected option is verified
				// 		this.formGroup.get('isVerified')?.clearValidators();
				// 	} else {
				// 		// Add Validators.requiredTrue if the option is not verified
				// 		this.formGroup.get('isVerified')?.setValidators([Validators.requiredTrue]);
				// 	}
				// 	// Update the validity status of the form control
				// 	this.formGroup.get('isVerified')?.updateValueAndValidity();
				// }
				return this.autofilter(value || '');
			})
		);
	}

	public autofilter(value: string): CompaniesList[] {
		const filterValue = value.toLowerCase();
		return this.myCompaniesList?.filter(
			(company: CompaniesList) => company.companyName?.toLowerCase()?.trim()?.includes(filterValue)
		);
	}

	public createOrganization(): void {
		if (this.formGroup.valid) {
			const isVerified = this.selectedCompanyOption?.isVerified ? true : false;
			const createOrganizationData = {
				companyName: this.formGroup.value.companyName.trim(),
				organizationName: this.formGroup.value.organizationName.trim(),
				isVerified
			};
			this.switchOrganizationService.createOrganization(createOrganizationData);
			this.dialogRef.close();
		}
	}
	/**
	 * Triggered when the input field gains focus.
	 * This method sets the `filteredOptions` observable to display all available companies
	 * from `myCompaniesList` in the autocomplete dropdown.
	 * Ensures that users see all options initially when they focus on the input field,
	 * even before typing any search query.
	 */
	public onInputFocus(): void {
		if (this.myCompaniesList?.length) {
			const validCompanies = this.myCompaniesList.filter((company) => company.companyName?.trim());
			this.filteredOptions = of(validCompanies);
		}
	}

	public closeDialog(): void {
		this.dialogRef.close();
	}

	/**
	 * Lifecycle hook that is called when the component is being destroyed.
	 * It unsubscribes from observables and cleans up any resources.
	 */
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
